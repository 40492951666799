






































































import {Component, Vue, Watch} from 'vue-property-decorator'
import {DataTableHeader} from "vuetify";
import Webservice from "@/assets/service/Webservice";
import {RequestType} from "@/assets/constants/Enums";
import {SchoolURLParams} from "@/assets/service/Endpoints";
import {Wrapper} from "@/assets/Entities/GenericEntity";

@Component({components: {}})
export default class GenericListComponent<T> extends Vue {
  headers!: DataTableHeader[];
  items: T[] = [];
  showInactive!: boolean;
  title!: string;
  addButtonTitle!: string;
  addButtonIcon!: string;
  elementActionIcon!: string;
  dialog!: string;
  checkBoxLabel!: string;
  options = {};
  totalItems: number = -1;
  loading: boolean = false;
  rowsPerPageText!: string;
  params!: SchoolURLParams;
  error: string = '';
  filterValues = [];
  searchInternal: string = '';
  canBeInactive: boolean = true;
  // _timerId: any = '';

  get search(): string {
    return this.searchInternal;
  }

  set search(search: string) {
    clearTimeout(this.$data._timerId)

    // delay new call 500ms
    this.$data._timerId = setTimeout(() => {
      this.searchInternal = search
    }, 500)
  }

  get filters(): Filter[] {
    return []
  };

  @Watch('searchInternal')
  @Watch('showInactive')
  @Watch('options', {deep: true, immediate: true})
  loadItems(): void {
    if (Object.keys(this.options).length) {
      this.loading = true;
      let options: any = this.options;
      options.search = this.search;
      if (this.canBeInactive) {
        options.active = this.showInactive ? 0 : 1;
      }
      Webservice.performRequest<Wrapper<T>>(RequestType.GET, this.params, this.options, (val) => {
            this.totalItems = (val as Wrapper<T>).count
            this.items = ((val as Wrapper<T>).items || []) as T[]
          },
          error => this.error = error?.response.error,
          () => this.loading = false)
    }
  }
}

export class Filter {
  title!: string;
  items!: { name: string, value: string }
  text!: string;
  value!: string;
}
