import GenericEntity from "@/assets/Entities/GenericEntity";

export default class School extends GenericEntity{
    _id!: string;
    name!: string;
    classes!: number;
    users!: number;
    active?: boolean;

    constructor(name?: string, classes?: number, students?: number) {
        super();
        this.name = name ?? '';
        this.classes = classes ?? 0;
        this.users = students ?? 0;
    }
}